.pointer{
    cursor: pointer;
}

.movieClickableZone{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1;
    cursor: pointer;
}

.movieListWidth{
    width: 80%;
}

.cadreMovieContainer{
    width: 100%;
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
}

.cadreMovieContainerMini{
    width: 100%;
    position: relative;
}

.cadreMovie{
    overflow: hidden;
    border-radius: 10px;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cadreMovieMini{
    overflow: hidden;
    border-radius: 10px;
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px dotted #4e4e4e;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 2s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 

.loaderCard {
    width: 215px;
    height: 215px;
    display: block;
    margin: auto;
    position: relative;
    background: #FFF;
    box-sizing: border-box;
}
.loaderCard::after {
    content: '';
    width: calc(100% - 30px);
    height: calc(100% - 15px);
    top: 15px;
    left: 15px;
    position: absolute;
    background-image: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5) 50%, transparent 100%),
    linear-gradient(#DDD 100px, transparent 0),
    linear-gradient(#DDD 16px, transparent 0),
    linear-gradient(#DDD 50px, transparent 0);
    background-repeat: no-repeat;
    background-size: 75px 175px, 100% 100px, 100% 16px, 100% 30px;
    background-position: -185px 0, center 0, center 115px, center 142px;
    box-sizing: border-box;
    animation: animloader 1s linear infinite;
}
  
@keyframes animloader {
    to {
        background-position: 185px 0, center 0, center 115px, center 142px;
    }
}

.imgBg{
    background-image: url('../img/barriere.png');
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat; 
    padding-top: 6px;
    padding-bottom: 6px;
}

hr {
    overflow: visible; /* For IE */
    padding: 0;
    border: none;
    border-top: medium double #333;
    color: #333;
    text-align: center;
    margin-bottom: 1.5em;
    margin-top: 1.5em;
}
hr:after {
    content: url('../img/potfleur.png');
    display: inline-block;
    position: relative;
    top: -0.9em;
    font-size: 1.5em;
    padding: 0 0.25em;
    background: white;
}

.objectFitContain{
    object-fit: contain;
}

.eventVideo{
    width: 100%;
    height: 250px;
}

.textBlue{
    color: #0081b8;
}
.textRed{
    color: #0081b8;
}
.positionDetailEvent{
    position: absolute;
    bottom: 0;
    width: 100%;
}

.videoBg{
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    width: 100vw !important;
    height: calc(100vw*0.5625) !important;
    transform: translate(-50%, -50%) !important;
    pointer-events: none !important;
}

.video-container{
    height: calc(100vw*0.5625) !important;
}

.select {
	width: 225px;
	height: 40px;
	cursor: pointer;
	background-color: white;
	box-shadow: 0 2px 0 white;
	border-radius: 2px;
}

.select_expand {
    width: 0;
    height: 40px;
    position: absolute;
    top: 0;
    right: 0;    
}

.select_expand::after {
    content: '\003E';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%) rotate(90deg) scaleY(1.75);
    color: #3e3e3e;
    font-size: 28px;
    pointer-events: none;
    z-index: 2;
    transition: all 250ms cubic-bezier(.4,.25,.3,1);
    opacity: .6;
}

.select_expand::after {
    opacity: 1
}

.select_expand::after {
    transform: translate(-50%, -50%) rotate(90deg) scaleX(-1) scaleY(1.75);
}

.select_expandLabel {
    display: block;
    width: 100%;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.select_close {
    display: none
}

.select_closeLabel {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
}

.select_items {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid #2fb5d1;
    border-radius: 2px;
    padding-top: 40px;
}

.select_input {
    display: none
}

.select_label {
    transition: all 250ms cubic-bezier(.4,.25,.3,1);
    display: block;
    height: 0;
    font-size: 1.2rem;
    line-height: 40px;
    overflow: hidden;
    color: #3e3e3e;
    background-color: #fff;
    cursor: pointer;
    padding-left: 20px;    
}

.select_label-placeholder {
    height: 40px;
    vertical-align: middle;
    position: absolute;
    top: 0;
    left: 0;
    opacity: .6;
    background-color: transparent;
}

.select_expand:checked {
    + .select_closeLabel {
        display: block;
        
        + .select_options {
            .select_label {
                height: 40px;
                &:hover {background-color: #f7f7f7}
            }
            
            + .select_expandLabel {display: none}
        }
    }
}

.select_input:checked + .select_label {
    height: 40px;
    margin-top: -40px;
}

@media (min-width: 672px) {
    .eventVideo {
        height: 350px;
    }
}

@media (min-width: 990px) {
    .eventVideo {
        height: 500px;
    }
}

@media (min-width: 1296px) {
    .eventVideo {
        height: 600px;
    }
    .movieListWidth{
        width: 65%;
    }
}

@media (max-width: 992px) {
    .movieListWidth{
        width: 70%;
    }
}